import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknClientsEditSfgsConfigEditLocationAccessController extends Controller {
  @service store;

  @tracked isPopoutVisible = false;
  @tracked isLoadingUnassignedLocations = false;

  @tracked assignedLocations = [];
  @tracked unassignedLocations = [];
  @tracked popout_location_name = '';
  @tracked popout_organization_id = null;

  @action
  async togglePopout() {
    set(this, 'isPopoutVisible', !this.isPopoutVisible);
    this.send('refreshModel');
  }

  @action
  async setFilteredLocations() {
    const locations = await this.getLocations();
    const assignedIds = new Set(this.locationTemplates.mapBy('location.id'));
    const unassignedLocations = locations.filter(
      location => !assignedIds.has(location.id)
    );

    set(this, 'unassignedLocations', unassignedLocations);
  }

  @action
  updatePopoutLocationName(value) {
    this._updatePopoutLocationName(value);
  }

  _updatePopoutLocationName(value) {
    set(this, 'popout_location_name', value);
    this.setFilteredLocations();
  }

  @action
  updatePopoutOrganizationId(value) {
    set(this, 'popout_organization_id', value);
    this.setFilteredLocations();
  }

  async getLocations() {
    return await this.store.query('v2/location', {
      per_page: 1000,
      client_id: this.contractTemplate.get('client.id'),
      archived: false,
      location_name: this.popout_location_name,
      organization_id: this.popout_organization_id
    });
  }
}
