import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageFinancialsInterestApplyRoute extends Route {

  @service router;
  @service session;

  async model() {
    const model = this.modelFor('authenticated.kases.manage');
    const selections = await this.store.query('v2/selection', {
      kase_id: model.id,
      per_page: 1000,
      type: 'KaseSelection',
      include: 'posted-by'
    })

    return selections;
  }

  beforeModel() {
    const { can_view_kases, can_view_case_financial, can_view_interest_section } = this.session.currentClient;
    const { canViewKases, canViewInterestSection, isOwner, isSuperUser } = this.session.currentUser;
    if (!(can_view_kases && can_view_case_financial && can_view_interest_section && canViewKases && (isSuperUser || isOwner || canViewInterestSection))) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }
}
